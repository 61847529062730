<template>
    <button :id="id" :class="[{'disabled-btn' : disabled}, btnClass]" :type="btnType" :style="{ height: '46px', pointerEvents: loading ? 'none' : 'all' }">
        <div v-if="showLoader">
            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                style="margin: auto; display: block; shape-rendering: auto;" width="20px" height="20px"
                viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
                <circle cx="50" cy="50" r="32" stroke-width="14" stroke="#FFFFFF"
                    stroke-dasharray="50.26548245743669 50.26548245743669" fill="none" stroke-linecap="round">
                    <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="1s"
                        keyTimes="0;1" values="0 50 50;360 50 50"></animateTransform>
                </circle>
            </svg>
        </div>
        <slot v-else />
    </button>
</template>

<script setup>
import { watch, ref } from 'vue';

const props = defineProps({
    id: { 
        type: String, 
        default: 'submitBtn' 
    },
    loading: { 
        type: Boolean, 
        default: false 
    },
    btnClass: { 
        type: String, 
        default: 'secondary-btn' ,
    },
    btnType: { 
        type: String, 
        default: 'button' 
    },
    disabled: {
        default: null
    }
});

const showLoader = ref();

watch(() => props.loading, (newValue)=> {
    showLoader.value = !!newValue;
})
</script>