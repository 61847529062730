<template>
    <div class="otp-input-container">
        <div class="otp-input-group">
            <template v-for="item in 6" :key="item">
                <input type="text" maxlength="1"
                    :class="['otp-input-box', { 'otp-input-box-error': hasError }, { 'otp-input-box-left': [1].includes(item) }, { 'otp-input-box-right': [6].includes(item) }]"
                    ref="inputRef" @paste="onPaste($event, item)" @focus="onFocus($event, item)" @input="processInput(item)"
                    v-model="modelValueTemp[item]" @keydown="keydownEvent" />
                <!-- <div v-if="item === 3" class="otp-input-dash"></div> -->
            </template>
            <input type="hidden" :value="joinedInputs" :name="name" />
        </div>
        <div v-if="validVar(error)" class="otp-input-error-text">
            <ErrorIcon />{{ error }}
        </div>
    </div>
</template>


<script setup>
import { ref, watchEffect, reactive } from 'vue';
import ErrorIcon from '@frontendSrc/assets/icons/ErrorIcon.vue';

const props = defineProps({
    name: {
        type: String
    },
    error: { 
        type: String, 
        default: null
    }
})

const totalChar = 6;

const emit = defineEmits(['update:modelValue', 'change']);

const joinedInputs = ref()
const inputRef = ref()
const modelValueTemp = reactive({});
const hasError = ref(false)
const activeElSer = ref()

for (var i = 1; 6 >= i; i++) {
    modelValueTemp[i] = '';
}

watchEffect(() => {
    if (joinedInputs.value != undefined || joinedInputs.value == '') {
        if (joinedInputs.value.length < totalChar || props.error) {
            hasError.value = true;
        } else {
            hasError.value = false;
        }
    } else {
        hasError.value = false;
    }
})

const validVar = (data) => {
    if (data === 0 || data === false) { return true }
    if (data) {
        if (data != null && data != undefined && data != '' && data != 'null' && data != 'undefined') {
            return true;
        } else {
            return false;
        }
    } else {
        return false;
    }
}
const onPaste = async (e, item) => {
    e.preventDefault()
    if (e.target.type != 'text') { return }
    //let text = await window.navigator.clipboard.readText();
    let text = await e.clipboardData.getData('text')
    text = text.replace(/[\s-]/g, '')
    if (isNaN(text)) {
        return
    }
    let pasteSer = 1;
    for (let i = 0; text.length > i; i++) {
        if (pasteSer > 0 && pasteSer <= totalChar) {
            modelValueTemp[pasteSer] = text[i];
            inputRef.value[pasteSer - 1].focus()
        }
        pasteSer++
    }
    joinedInputs.value = JoinValue();
    emit('change', joinedInputs.value);
}
const onFocus = (e, ser) => {
    e.target.select();
    activeElSer.value = ser;
}
const JoinValue = () => {
    let fullCode = '';
    for (let key in modelValueTemp) {
        fullCode = fullCode + modelValueTemp[key];
    }
    return fullCode
}
const processInput = (item) => {
    if (inputRef.value[item] && validVar(inputRef.value[item - 1].value)) {
        inputRef.value[item].focus()
        inputRef.value[item].select()
    }
    joinedInputs.value = JoinValue();
    emit('change', joinedInputs.value);
}
const keydownEvent = (e) => {
    if (activeElSer.value) {
        let toFocus = null
        if (e.key == 'Backspace' && activeElSer.value > 1) {
            toFocus = activeElSer.value - 2;
            modelValueTemp[activeElSer.value] = '';
            joinedInputs.value = JoinValue();
            emit('change', joinedInputs.value);
        } else if (e.key == 'Backspace' && activeElSer.value === 1) {
            modelValueTemp[activeElSer.value] = '';
            joinedInputs.value = JoinValue();
            emit('change', joinedInputs.value);
        } else if (e.key == 'ArrowRight' && activeElSer.value < totalChar) {
            toFocus = activeElSer.value;
        } else if (e.key == ('ArrowLeft') && activeElSer.value > 1) {
            toFocus = activeElSer.value - 2;
        }
        if (toFocus != null) {
            inputRef.value[toFocus].focus()
            inputRef.value[toFocus].select()
        }
    }
    if (e.ctrlKey === true || e.altKey === true || e.metaKey === true || ['Tab'].includes(e.key)) { return }
    if (isNaN(e.key)) {
        e.preventDefault()
        return
    }
}
</script>
