import { defineStore } from 'pinia';
import { useErrorStore } from '@frontendSrc/store/error';

export const useRegistrationStore = defineStore({
    id: 'registration',
    state: () => ({
        isLoading: false,
        isFullPageLoading: false,
        activeStep: 1,
        steps: [1, 2, 3],
        registrationId: null,
        profileStep: {
            formData: {
                first_name: "",
                last_name: "",
                email: "",
                password: "",
                password_confirmation: ""
            },
            isUpdateMode: false,
        },
        otpStep: {
            otpExpTime: 0
        },
        companyStep: {
            formData: {
                company_name: "",
                url_prefix: "",
                country_id: "",
                timezone_id: "",
                email: ""
            },
        }
    }),
    actions: {
        setActiveStep(step) {
            this.activeStep = step;
        },
        setIsFullPageLoading(status = false) {
            this.isFullPageLoading = status;
        },
        setIsLoading(status = false) {
            this.isLoading = status;
        },
        setRegistrationId(id) {
            this.registrationId = id;
        },
        getSteps() {
            return {
                1: 'Profile Setup',
                2: 'Verify Email',
                3: 'Company Setup',
            };
        },
        changePrevTab() {
            let prevTabIndex = this.tabs.indexOf(this.activeTab) - 1;
            if (prevTabIndex >= 0 && prevTabIndex <= this.tabs.length) { this.activeTab = this.tabs[prevTabIndex]; }
        },
        changeNextTab() {
            let nextTabIndex = this.tabs.indexOf(this.activeTab) + 1;
            if (nextTabIndex > 0 && nextTabIndex < this.tabs.length) { this.activeTab = this.tabs[nextTabIndex]; }
        },
        async handleClickStep(step) {
            if (this.activeStep === step) return;
            if (this.activeStep > step) this.activeStep = step;
        },
        async getTempTenant(tenantId) {
            const errorStore = useErrorStore();
            this.setIsFullPageLoading(true);
            errorStore.setError();

            return axios.get(`${appPublicConfig.url}/api/request/tenant/${tenantId}`)
                .then(response => {
                    this.setIsFullPageLoading();
                    let data = response.data.data;
                    this.profileStep.isUpdateMode = true;
                    this.registrationId = data?.ulid;
                    this.profileStep.formData = {
                        first_name: data?.first_name,
                        last_name: data?.last_name,
                        email: data?.email,
                        password: "",
                        password_confirmation: ""
                    }
                    this.activeStep = (data?.completed_step < 3 ? data?.completed_step + 1 : 3) || 1;
                    this.otpStep.otpExpTime = data?.retry_after || 0;
                })
                .catch(error => {
                    errorStore.setError(error);
                    this.setIsFullPageLoading();
                });
        },
        async submitProfileStep(payload) {
            const errorStore = useErrorStore();
            this.setIsLoading(true);
            errorStore.setError();

            return axios.post(`${appPublicConfig.url}/api/request/tenant/step-one`, payload)
                .then(response => {
                    const data = response.data.data;

                    this.registrationId = data?.ulid;
                    this.activeStep = (data?.completed_step < 3 ? data?.completed_step + 1 : 3) || 1;
                    this.profileStep.isUpdateMode = true;
                    this.profileStep.formData.password = "";
                    this.profileStep.formData.password_confirmation = "";
                    this.setIsLoading();

                    this.otpStep.otpExpTime = data?.retry_after || 0;
                    history.replaceState({}, null, '/request/' + this.registrationId);
                }).catch(error => {
                    errorStore.setError(error);
                    this.setIsLoading();
                });
        },
        async getVerificationCode(code) {
            const errorStore = useErrorStore();
            this.setIsLoading(true);
            errorStore.setError();

            return axios.post(`${appPublicConfig.url}/api/request/tenant/verify-otp`, { code, ulid: this.registrationId, email: this.profileStep.formData.email })
                .then(response => {
                    this.setIsLoading();
                    this.activeStep = 3;
                }).catch(error => {
                    errorStore.setError(error)
                    this.setIsLoading();
                });
        },
        async resendOtp() {
            this.setIsLoading(true);
            const errorStore = useErrorStore();
            errorStore.setError();

            return axios.get(`${appPublicConfig.url}/api/request/tenant/${this.registrationId}/resend-otp`)
                .then(response => {
                    this.setIsLoading();
                    this.otpStep.otpExpTime = response.data.data?.retry_after || 0;
                })
                .catch(error => {
                    errorStore.setError(error)
                    this.setIsLoading();
                });
        },
        async checkUrlPrefix(urlPrefix) {
            const errorStore = useErrorStore();
            errorStore.setError();
            const payload = {
                url_prefix: urlPrefix,
                ulid: this.registrationId
            };

            return axios.post(`${appPublicConfig.url}/api/request/tenant/check-url-prefix`, payload)
                .then(response => {

                })
                .catch(error => {
                    errorStore.setError(error);
                });
        },
        async submitCompanyStep(payload) {
            const errorStore = useErrorStore();
            this.setIsLoading(true);
            errorStore.setError();

            return axios.post(`${appPublicConfig.url}/api/request/tenant/step-three`, payload)
                .then(response => {
                    const data = response.data;
                    window.location.href = `/request/${data.reference_id}/${data.pending ? 'pending' : 'complete'}`;
                }).catch(error => {
                    errorStore.setError(error);
                    this.setIsLoading();
                });
        },
    }
});