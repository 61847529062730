<template>
    <div class="item-container">
        <Card v-if="!registrationStore.isLoading">
            <form :class="[{ 'loading-animation-container': registrationStore.isLoading }]" @submit.prevent="handleFormSubmit"  @keypress.enter="manageEnter">
                <h2 class="card-header">Create Workspace Account</h2>
                <div class="input-title">Company Name<span class="input-title-required">*</span></div>
                <input 
                    type="text" 
                    :class="['input-box', { 'input-box-error-border': formErrors?.company_name }]"
                    @blur="validateEmptyString('company_name')" 
                    placeholder="eg. Apple Inc."
                    v-model="registrationStore.companyStep.formData.company_name" 
                />
                <InputErrorMessage :message="formErrors?.company_name" />
                <div class="input-title">URL Prefix<span class="input-title-required">*</span></div>
                <div class="url-prefix">
                    <div class="url-prefix-domain">
                        <input 
                            type="text" 
                            class="input-box"
                            :class="['input-box', { 'input-box-error-border': formErrors.url_prefix }]"
                            @input="urlPrefixChange"
                            placeholder="eg. appleinc."
                            v-model="registrationStore.companyStep.formData.url_prefix"
                        />
                    </div>
                    <div class="url-prefix-domain">
                        .crewlix.com
                    </div>
                </div>
                <div class="input-error-box">
                    <span class="input-error-box-content" v-if="formErrors.url_prefix">
                        <ErrorIcon />{{ formErrors.url_prefix }}
                    </span>
                    <span class="input-success-box-content" v-else-if="isValidUrlPrefix">
                        <CheckedIcon style="width: 10px; height: 12px;" successColor />This domain is available.
                    </span>
                </div>
                <hr class="workspace-divider" />
                <div class="two-column">
                    <div>
                        <div class="input-title">Country<span class="input-title-required">*</span></div>
                        <select 
                            class="select-box" 
                            v-model="registrationStore.companyStep.formData.country_id"
                            @blur="validateEmptyString('country_id')"
                        >
                            <option value="" disabled>---Select---</option>
                            <option v-for="country in countries" :value="country.id">{{ country.name }}</option>
                        </select>
                        <InputErrorMessage :message="formErrors?.country_id" />
                    </div>
                    <div>
                        <div class="input-title">Time Zone<span class="input-title-required">*</span></div>
                        <select 
                            class="select-box" 
                            v-model="registrationStore.companyStep.formData.timezone_id"
                            @blur="validateEmptyString('timezone_id')"
                        >
                            <option value="" disabled>---Select---</option>
                            <option v-for="timezone in timezones" :value="timezone.id">{{ timezone.title }}</option>
                        </select>
                        <InputErrorMessage :message="formErrors?.timezone_id" />
                    </div>
                </div>
                <InputErrorMessage :message="companyStepError" v-if="companyStepError" />
                <Button :btnType="'submit'" :id="'companyStepSubmitBtn'" style="margin-top: 10px" :disabled="Object.keys(formErrors).length !== 0" :loading="registrationStore.isLoading">
                    Continue
                </Button>
            </form>
        </Card>
        <Card v-else>
            <h2 class="card-header" style="margin-bottom:10px">Verifying Information</h2>
            <ProgressAnimation />
        </Card>

    </div>
</template>

<script setup>
import { useRegistrationStore } from '@frontendSrc/store/registration';
import { useErrorStore } from '@frontendSrc/store/error';
import InputErrorMessage from '@frontendSrc/components/global/InputErrorMessage.vue';
import Button from '@frontendSrc/components/global/Button.vue';
import Card from '@frontendSrc/components/global/Card.vue';
import { onMounted, ref } from 'vue';
import companyCycles from '@frontendSrc/constants/companyCycles';
import ErrorIcon from '@frontendSrc/assets/icons/ErrorIcon.vue';
import CheckedIcon from '@frontendSrc/assets/icons/CheckedIcon.vue';
import debounce from '@frontendSrc/utils/debounce';
import ProgressAnimation from '@frontendSrc/components/registration/ProgressAnimation.vue';

import { convertToTitleCase } from '@frontendSrc/utils/validationHelper';

// Initiate Store
const registrationStore = useRegistrationStore();
const errorStore = useErrorStore();

const countries = ref(appPublicConfig.countries);
const timezones = ref(appPublicConfig.timezones);
const isValidUrlPrefix = ref(false);
const companyStepError = ref(null);

let formErrors = ref({});

onMounted(() => {
    setTimezoneFromLocal();
    setCountryFromLocal();
});

const setTimezoneFromLocal = () => {
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    timezones.value.forEach((timeZone, key) => {
        if (timeZone.title == userTimeZone) {
            registrationStore.companyStep.formData.timezone_id = timeZone.id;
        }
    })
}

const setCountryFromLocal = () => {
    const userCountry = (new Date()).toString().split('(')[1].split(" ")[0];

    countries.value.forEach((country, key) => {
        if (country.name == userCountry) {
            registrationStore.companyStep.formData.country_id = country.id;
        }
    })
}

const validateEmptyString = (key) => {
    delete formErrors.value[key];
    if( typeof registrationStore.companyStep.formData[key] == 'string' && (registrationStore.companyStep.formData[key].length < 3 || registrationStore.companyStep.formData[key].length > 50) ) formErrors.value[key] = `Please use between 3-50 characters.`;
    if( registrationStore.companyStep.formData[key] === "" ) formErrors.value[key] = `This is a required field.`;
}

const validateUrlPrefix = () => {
    delete formErrors.value.url_prefix;
    const urlPrefix = registrationStore.companyStep.formData.url_prefix;

    if( urlPrefix === "" ){
        formErrors.value.url_prefix = "URL Prefix can not be empty";
    }
    else if ( urlPrefix.length < 3 || urlPrefix.length > 50){
        formErrors.value.url_prefix = "Please use between 3-50 characters.";
    }
    else if ( ! /^[a-z0-9-_]+$/.test(urlPrefix)){
        formErrors.value.url_prefix = "Usable characters: (a-z, 0-9), ‘_’ and ‘-’.";
    }
}

const urlPrefixChange = debounce(() => checkAvailabilityOfSubdomain());

const checkAvailabilityOfSubdomain = async() => {
    delete formErrors.value.url_prefix;
    isValidUrlPrefix.value = false;
    const urlPrefix = registrationStore.companyStep.formData.url_prefix;

    validateUrlPrefix();

    if(formErrors.value.url_prefix) return;

    await registrationStore.checkUrlPrefix(urlPrefix);

    if(errorStore.errorCode && errorStore.errorMessage){
        formErrors.value.url_prefix = errorStore.errorMessage;
    }else{
        isValidUrlPrefix.value = true;
    }
}

const validateFormData = async() => {
    validateEmptyString('company_name');
    validateEmptyString('country_id');
    validateEmptyString('timezone_id');
    validateUrlPrefix();
}

const handleFormSubmit = async() => {
    companyStepError.value = null;

    validateFormData();

    if( Object.keys(formErrors.value).length ) return;

    const payload = {
        ...registrationStore.companyStep.formData,
        ...{
            email: registrationStore.profileStep.formData.email,
            ulid: registrationStore.registrationId,
        }
    }

    await registrationStore.submitCompanyStep(payload);

    if( errorStore.errorCode === 422 ){
        formErrors.value = errorStore.formErrors || {};
        return;
    }

    if( errorStore.errorCode && errorStore.errorMessage){
        companyStepError.value = errorStore.errorMessage;
    }
}
const manageEnter = (e) => {
    if(registrationStore.isLoading){
        e.preventDefault();
    }
}
</script>