export const checkHasMinimumCharacter = (password, length = 8) => {
    return password.length >= length || false;
}

export const checkHasOneSpecialCharacter = (password) => {
    return /[ `!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/.test(password) || false;
}

export const checkHasOneUppercaseCharacter = (password) => {
    return /[A-Z]/.test(password) || false;
}

export const checkIfPasswordMatched = (password, passwordConfirmation) => {
    return password === passwordConfirmation;
}

export const checkWhetherEmailValid = (email) => {
    return !!(email !== "" && /^[^@]+@\w+(\.\w+)+\w$/.test(email));
}

export const convertToTitleCase = (inputString) => {
    if(!inputString || inputString === "") return inputString;

    const words = inputString.split('_');
  
    const titleCaseString = words.map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
  
    return titleCaseString;
}