<template>
    <div class="app-container">
        <Header />
        <StepTab />
        <ProfileStep v-if="registrationStore.activeStep == 1"/>
        <OtpStep v-if="registrationStore.activeStep == 2" />
        <CompanyStep v-if="registrationStore.activeStep == 3"/>
    </div>
    <FullPageLoader v-if="registrationStore.isFullPageLoading" />
</template>

<script setup>
import { useRegistrationStore } from '@frontendSrc/store/registration';
import ProfileStep from '@frontendSrc/components/registration/ProfileStep.vue';
import OtpStep from '@frontendSrc/components/registration/OtpStep.vue';
import CompanyStep from '@frontendSrc/components/registration/CompanyStep.vue';
import Header from '@frontendSrc/components/registration/Header.vue';
import StepTab from '@frontendSrc/components/registration/StepTab.vue';
import FullPageLoader from '@frontendSrc/components/global/FullPageLoader.vue';
import { onMounted } from 'vue';

// Initiate Store
const registrationStore = useRegistrationStore();

onMounted(() => {
    const url = new URL(window.location.href);
    const splittedUrl = url.pathname.split('/');
    
    if(splittedUrl[1] === 'request' && splittedUrl[2]){
        registrationStore.getTempTenant(splittedUrl[2]);
    }
});
</script>