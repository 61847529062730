<template>
    <div class="item-container">
        <div class="stepper-wrap">
            <div class="stepper-box-container" v-for="(step, index) in registrationStore.getSteps()">
                <div 
                    :class="['stepper-box', registrationStore.activeStep > index ? 'stepper-box-complete' : index == registrationStore.activeStep ? 'stepper-box-active' : '']"
                    :style="{pointerEvents: registrationStore.isLoading || (index == 2 && registrationStore.activeStep == 3) || index >= registrationStore.activeStep ? 'none' : 'all', cursor: 'pointer'}"
                    @click="registrationStore.handleClickStep(index)"
                >
                    <StepCompleteIcon v-if="registrationStore.activeStep > index" />
                    <span v-else class="stepper-box-number">{{ index }}</span>
                    <div class="stepper-title">{{ step }}</div>
                </div>
                <div class="stepper-line"></div>
            </div>
        </div>
    </div>
</template>
<script setup>
import { useRegistrationStore } from '@frontendSrc/store/registration';
import StepCompleteIcon from '@frontendSrc/assets/icons/StepCompleteIcon.vue';

// Initiate Store
const registrationStore = useRegistrationStore();
</script>