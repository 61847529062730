/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

import axios from "axios";

window.axios = axios.create({
    baseURL: import.meta.env.VITE_API_URL,
    headers: {
        'Cache-Control'     : 'no-cache',
        'Accept'            : 'application/json'
    },
});

window.axios.defaults.withCredentials = true;