import { defineStore } from 'pinia'

export const useErrorStore = defineStore({
    id: 'error',
    state: () => ({
        error: {},
        errorCode: null,
    }),
    getters: {
        formErrors: state => {
            if (state.error && state.error.response.status === 422) {
              
                const errors = state.error.response.data.errors;
              
                return Object.keys(errors).reduce((errorBag, key) => {
                    errorBag[key] = errors[key][0];
                    return errorBag;
                }, {});
            }

            return {};
        },
        errorMessage: state => {
            return Object.keys(state.error).length != 0 ? state.error?.response?.data?.message : ''
        }
    },
    actions: {
        setError(error = {})
        {
            this.error = error;
          
            if (error?.code === 'ERR_NETWORK') {
              console.log('Connection is not ok');
            }
          
            this.errorCode = error?.response?.status || null;
        },
        setErrorCode(code = null)
        {
            this.errorCode = code;
        }
    }
})