import '@frontendSrc/bootstrap';
import pinia from "@frontendSrc/store/index.js";
import { createApp } from 'vue';
import App from '@frontendSrc/App.vue';
import { VueReCaptcha } from "vue-recaptcha-v3";

const app = createApp(App);

app.use(pinia)

if( appPublicConfig?.recaptcha?.enabled ){
    app.use(VueReCaptcha, { siteKey: appPublicConfig?.recaptcha?.key })
}
    
app.mount('#app');