<template>
    <div>
        <div class="item-container">
            <Card :isLoading="registrationStore.isLoading">
                <h2 class="card-header">Check Your Email!</h2>
                <div class="sub-text">
                    We’ve sent a 6 digit confirmation code to <span class="sub-text-highlight">{{ registrationStore.profileStep.formData.email }}</span>. Remember to check the spam folder as well.
                </div>
                <OTP @change="applyOTP" :error="otpError" />
            </Card>
        </div>
        <div>
            <div class="footer-text-container" v-if="!registrationStore.isLoading">
                <div class="footer-text" v-if="registrationStore.otpStep.otpExpTime === 0">
                    <span class="footer-text-highlight" @click="resendOTP">Resend now,</span> in case the first one didn’t work.
                </div>
                <div v-else>
                    <span class="footer-text-otp">OTP sent successful! Please check your email.</span><br>
                    <span class="footer-text-otp-sub-text">You can request a new OTP after {{ registrationStore.otpStep.otpExpTime }} seconds.</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import OTP from '@frontendSrc/components/registration/OTP.vue';
import Card from '@frontendSrc/components/global/Card.vue';
import { useRegistrationStore } from '@frontendSrc/store/registration';
import { useErrorStore } from '@frontendSrc/store/error';

// Initiate Store
const registrationStore = useRegistrationStore();
const errorStore = useErrorStore();

const otpError = ref();
let interval;

/**
 * applyOTP
 * @param otp
 */
const applyOTP = (otp) => {
    if (typeof otp != 'undefined' && ('' + otp + '').length == 6) {
        setTimeout(async() => {
            otpError.value = null;
            await registrationStore.getVerificationCode(otp);
            if(errorStore.errorCode) otpError.value = errorStore.errorMessage;
        }, 200);
    }
}

const updateCountdown = () => {
  if (registrationStore.otpStep.otpExpTime > 0) {
    registrationStore.otpStep.otpExpTime--;
    return;
  }
  
  clearInterval(interval);
}

/**
 * resendOTP
 */
const resendOTP = async() => {
    await registrationStore.resendOtp();
    interval = setInterval(updateCountdown, 1000);
}

onMounted(() => {
    interval = setInterval(updateCountdown, 1000);
})
</script>