<template>
    <div class="item-container">
        <Card :isLoading="registrationStore.isLoading">
            <form @submit.prevent="handleFormSubmit" @keypress.enter="manageEnter">
                <h2 class="card-header">Create Admin Profile</h2>
                <div class="input-two-col">
                    <div>
                        <div class="input-title">First Name<span class="input-title-required">*</span></div>
                        <input 
                            name="first_name"
                            type="text" 
                            v-model="registrationStore.profileStep.formData.first_name"
                            :class="['input-box', { 'input-box-error-border': formErrors?.first_name }]"
                            @blur="validateEmptyString('first_name')"
                            placeholder="eg. Steve" 
                        />
                        <InputErrorMessage :message="formErrors?.first_name" />
                    </div>

                    <div>
                        <div class="input-title">Last Name<span class="input-title-required">*</span></div>
                        <input
                            name="last_name"
                            type="text" 
                            v-model="registrationStore.profileStep.formData.last_name"
                            @blur="validateEmptyString('last_name')"
                            :class="['input-box', { 'input-box-error-border': formErrors?.last_name }]"
                            placeholder="eg. Musk"
                        />
                        <InputErrorMessage :message="formErrors?.last_name" />
                    </div>
                </div>

                <div class="input-title">Your Work Email<span class="input-title-required">*</span></div>
                <input 
                    type="email" 
                    v-model="registrationStore.profileStep.formData.email"
                    @blur="validateEmail"
                    :class="['input-box', { 'input-box-error-border': formErrors?.email }]"
                    placeholder="eg. musk@apple.com" 
                    name="email"
                />
                <InputErrorMessage :message="formErrors?.email" />

                <div class="input-title">Create Password<span v-if="!registrationStore.profileStep.isUpdateMode" class="input-title-required">*</span></div>
                <div class="password-wrap">
                    <input
                        v-model="registrationStore.profileStep.formData.password"
                        :type="isPasswordVissible ? 'text' : 'password'"
                        @blur="validatePassword"
                        @input="onInputPasswordMatchCheck"
                        :class="['input-box', { 'input-box-error-border': formErrors?.password }]"
                        placeholder="Password" 
                        name="password"
                    />
                    <EyeCloseIcon class="icon" v-if="isPasswordVissible" @click="isPasswordVissible = !isPasswordVissible"/>
                    <EyeOpenIcon class="icon" v-else @click="isPasswordVissible = !isPasswordVissible"/>
                </div>
                <InputErrorMessage :message="formErrors?.password" />

                <div class="password-wrap">
                    <input 
                        v-model="registrationStore.profileStep.formData.password_confirmation"
                        :type="isPasswordConfirmationVissible ? 'text' : 'password'"
                        :class="['input-box', { 'input-box-error-border': formErrors?.password_confirmation }]"
                        @blur="validateRetypePassword"
                        @input="passwordValidationStatus.passwordMatched = checkIfPasswordMatched(registrationStore.profileStep.formData.password, registrationStore.profileStep.formData.password_confirmation)"
                        placeholder="Re-enter Password" 
                        name="password_confirmation"
                    />
                    <EyeCloseIcon class="icon" v-if="isPasswordConfirmationVissible" @click="isPasswordConfirmationVissible = !isPasswordConfirmationVissible"/>
                    <EyeOpenIcon class="icon" v-else @click="isPasswordConfirmationVissible = !isPasswordConfirmationVissible"/>
                </div>
                <InputErrorMessage :message="formErrors?.password_confirmation" />

                <div class="password-checker">
                    <div class="password-checker-item">
                        <CheckmarkFilledIcon class="password-checker-item-svg" :active="passwordValidationStatus.hasMinimumLength" />
                        <span class="password-checker-item-text">Minimum 8 characters</span>
                    </div>
                    <div class="password-checker-item">
                        <CheckmarkFilledIcon class="password-checker-item-svg" :active="passwordValidationStatus.hasUpperCaseCharacter" />
                        <span class="password-checker-item-text">One uppercase character</span>
                    </div>
                    <div class="password-checker-item">
                        <CheckmarkFilledIcon class="password-checker-item-svg" :active="passwordValidationStatus.hasSpecialCharacter" />
                        <span class="password-checker-item-text">One special character</span>
                    </div>
                    <div class="password-checker-item">
                        <CheckmarkFilledIcon class="password-checker-item-svg" :active="passwordValidationStatus.passwordMatched" />
                        <span class="password-checker-item-text">Passwords matched</span>
                    </div>
                </div>
                <InputErrorMessage :message="profileStepError" v-if="profileStepError" />
                <Button btnType="submit" :id="'profileStepSubmitBtn'" :loading="registrationStore.isLoading" :disabled="Object.keys(formErrors).length !== 0">
                    Let’s Roll
                </Button>
                <div class="terms-and-condition">
                    By continuing you agree to our 
                    <a href="https://www.crewlix.com/terms-of-use/" target="_blank" class="terms-and-condition-link">
                        Terms
                    </a> 
                    and 
                    <a href="https://www.crewlix.com/privacy-policy/" target="_blank" class="terms-and-condition-link">
                        Privacy Policy
                    </a>
                </div>
            </form>
        </Card>
    </div>
</template>

<script setup>
import { useReCaptcha } from 'vue-recaptcha-v3';
import { setCookie } from "@frontendSrc/utils/cookie";
import { useRegistrationStore } from '@frontendSrc/store/registration';
import { useErrorStore } from '@frontendSrc/store/error';
import InputErrorMessage from '@frontendSrc/components/global/InputErrorMessage.vue';
import Button from '@frontendSrc/components/global/Button.vue';
import Card from '@frontendSrc/components/global/Card.vue';
import EyeCloseIcon from '@frontendSrc/assets/icons/EyeCloseIcon.vue';
import EyeOpenIcon from '@frontendSrc/assets/icons/EyeOpenIcon.vue';
import CheckmarkFilledIcon from '@frontendSrc/assets/icons/CheckmarkFilledIcon.vue';
import { ref, onMounted } from 'vue';
import { checkHasMinimumCharacter, checkHasOneSpecialCharacter, checkHasOneUppercaseCharacter, checkIfPasswordMatched, checkWhetherEmailValid, convertToTitleCase } from '@frontendSrc/utils/validationHelper';

// Initiate Store
const registrationStore = useRegistrationStore();
const errorStore = useErrorStore();

const leadCookieTime = 30; // 30 Days

const isPasswordVissible = ref(false);
const isPasswordConfirmationVissible = ref(false);
const recaptcha = ref();
const profileStepError = ref(null);

let passwordValidationStatus = ref({
    hasMinimumLength: false,
    hasUpperCaseCharacter: false,
    hasSpecialCharacter: false,
    passwordMatched: false
});
            
let formErrors = ref({});

let executeRecaptcha, recaptchaLoaded;

onMounted(() => {
    if ( appPublicConfig?.recaptcha?.enabled ) {
        const recaptchaFunctions = useReCaptcha();
        executeRecaptcha = recaptchaFunctions.executeRecaptcha;
        recaptchaLoaded = recaptchaFunctions.recaptchaLoaded;
    }
});

const setRefSource = () => {
    const queryParams = new URLSearchParams(window.location.search);
    const ref = queryParams.get('ref');

    if(ref){
        setCookie('lead_ref_source', ref, leadCookieTime);
    }
}

const setHttpReferrer = () => {
    const httpReferrer = document.referrer || null;
    
    if( httpReferrer & ! httpReferrer?.includes(window.location.hostname) ){
        setCookie('lead_http_referrer', httpReferrer, leadCookieTime);
    }
}

onMounted(() => {
    setRefSource();
    setHttpReferrer();
});

const validateEmail = () => {
    delete formErrors.value.email;

    if(registrationStore.profileStep.formData.email === "") {
        formErrors.value.email = 'This is a required field.';
        return;
    }

    if(registrationStore.profileStep.formData.email.length > 50) {
        formErrors.value.email = 'Please use upto 50 characters.';
        return;
    }

    if( ! checkWhetherEmailValid(registrationStore.profileStep.formData.email) ){
        formErrors.value.email =  "Please enter a valid email.";
    }
}

const livePasswordValidation = () => {
    const password = registrationStore.profileStep.formData.password;
    passwordValidationStatus.value = {
        hasMinimumLength: checkHasMinimumCharacter(password),
        hasUpperCaseCharacter: checkHasOneUppercaseCharacter(password),
        hasSpecialCharacter: checkHasOneSpecialCharacter(password),
    }
}

const validatePassword = () => {
    const formData = registrationStore.profileStep.formData;
    delete formErrors.value.password;

    livePasswordValidation();

    if (formData.password === "") {
        formErrors.value.password = 'Password cannot be empty.';
    } else if (!passwordValidationStatus.value.hasMinimumLength || !passwordValidationStatus.value.hasUpperCaseCharacter || !passwordValidationStatus.value.hasSpecialCharacter) {
        formErrors.value.password = 'Please match following criteria.';
    }
}

const onInputPasswordMatchCheck = () => {
    livePasswordValidation()
    delete formErrors.value.password;
    if(registrationStore.profileStep.formData.password_confirmation){
        validateRetypePassword();
    }
}

const validateRetypePassword = () => {
    delete formErrors.value.password_confirmation;
    passwordValidationStatus.value.passwordMatched = false;
    const formData = registrationStore.profileStep.formData;

    if(formData.password_confirmation === "") {
        formErrors.value.password_confirmation = 'Retype Password cannot be empty.';
        return;
    }

    passwordValidationStatus.value.passwordMatched = checkIfPasswordMatched(formData.password, formData.password_confirmation);

    if( ! passwordValidationStatus.value.passwordMatched ){
        formErrors.value.password_confirmation = "Passwords did not match.";
    }
}

const validateEmptyString = (key) => {
    delete formErrors.value[key];
    if( typeof registrationStore.profileStep.formData[key] == 'string' && registrationStore.profileStep.formData[key].length > 50 ) formErrors.value[key] = `Please use upto 50 characters.`;
    if( registrationStore.profileStep.formData[key] === "" ) formErrors.value[key] = `This is a required field.`;
}

const validateFormData = () => {
    validateEmptyString('first_name');
    validateEmptyString('last_name');
    validateEmail();
    if( registrationStore.profileStep.isUpdateMode ) return;
    validatePassword();
    validateRetypePassword();
}

const validateCaptcha = async() => {
    recaptcha.value = null;
    if( ! appPublicConfig?.recaptcha?.enabled ) return;

    if ( ! navigator?.onLine ) {
        profileStepError.value = "Please check and confirm your internet connection, then refresh the page and try again..";
        return;
    }

    try {
        await recaptchaLoaded();
        recaptcha.value = await executeRecaptcha('steOneForm');
    } catch (error) {
        console.error('Error:', error);
        profileStepError.value = "Failed to generate reCAPTCHA. Please refresh the page and try again.";
    }
}

const handleFormSubmit = async() => {
    validateFormData();

    if( Object.keys(formErrors.value).length ) return;

    await validateCaptcha();

    let payload = registrationStore.registrationId ? { ...registrationStore.profileStep.formData, ulid: registrationStore.registrationId } : { ...registrationStore.profileStep.formData };

    Object.keys(payload).forEach(key => {
        if (payload[key] === '') {
            payload[key] = null;
        }
    });

    if( recaptcha.value ) payload.recaptcha = recaptcha.value;

    // Pass the Selected Plan to the payload
    const queryParams = new URLSearchParams(window.location.search);
    const selectedPlan = queryParams.get('plan');

    if(selectedPlan) payload.plan = selectedPlan;

    await registrationStore.submitProfileStep(payload);

    if( errorStore.errorCode === 422 ){
        formErrors.value = errorStore.formErrors || {};
        return;
    }

    if( errorStore.errorCode && errorStore.errorMessage){
        profileStepError.value = errorStore.errorMessage;
    }
}
const manageEnter = (e) => {
    if(registrationStore.isLoading){
        e.preventDefault();
    }
}
</script>